<template>
  <div class="j-footer-banner d-flex justify-center flex-column-reverse flex-lg-row">
    <div class="j-footer-banner__block j-footer-banner__block--left flex-grow-1 w-auto text-center text-lg-left">
      <h2 class="j-footer-banner__text j-footer-banner__text--banner-title j-footer-banner__text--white">
        {{ $t('footer_banner_title') }}
      </h2>
      <div class="j-footer-banner__text j-footer-banner__text--banner-description pt-6 pt-lg-7 pb-11 pb-lg-0">
        {{ $t('footer_banner_description') }}
      </div>
    </div>
    <div class="j-footer-banner__block j-footer-banner__block--right">
      <div class="j-footer-banner__image h-auto">
        <v-img
          :lazy-src="useImgSrc(images[bannerImgName])"
          :src="useImgSrc(images[bannerImgName])"
          alt="banner"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const isMobile = inject<Ref<boolean>>('isMobile');
const images = useAssetsImages();
const bannerImgName = computed(() => {
  return isMobile?.value
    ? 'footerBannerImgMob'
    : 'footerBannerImgDesktop';
});
</script>

<style lang="scss" scoped>
.j-footer-banner {
  position: relative;
  z-index: 0;
  @media(min-width: 1800px) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 50vw;
      z-index: 0;
    }
    &::before {
      background-color: $j-bg-block-light;
      transform: translateX(-100%);
      left: 0;
    }
    &::after {
      background-color: $j-color-primary;
      transform: translateX(50%);

    }
  }
}
.j-footer-banner__block {
  position: relative;
  &.j-footer-banner__block--left {
    background-color: $j-bg-block-light;
    padding-top: 100px;
    padding-left: 24px;
    padding-right: 24px;
    @media(min-width: 1280px) {
      padding: 57px 20px 57px 154px;
      z-index: 1;
    }
  }
  &.j-footer-banner__block--right {
    background-color: $j-color-primary;
    height: 87px;
    @media(min-width: 1280px) {
      height: auto;
      min-width: 24%;
    }
  }
}

.j-footer-banner__text {
  color: $j-text-secondary;
  font-size: 14px;
  @media(min-width: 1280px) {
    max-width: 618px;
  }
  &.j-footer-banner__text--white {
    color: $j-text-regular;
  }
  &.j-footer-banner__text--banner-title {
    font-family: $j-font-family-bebas-neue;
    font-size: 30px;
    line-height: 35px;
    @media(min-width: 1280px) {
      font-size: 54px;
      line-height: 57px;
    }
  }
}

.j-footer-banner__image {
  pointer-events: none;
  width: 100%;
  max-width: 340px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  z-index: 1;
  @media(min-width: 1280px) {
    width: 600px;
    max-width: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-62%, -47%);
  }
  @media(min-width: 1400px) {
    width: 740px;
  }
}
</style>
