<template>
  <div class="d-flex justify-center align-center flex-wrap j-footer-items-list">
    <div
      v-for="(item, index) of processedItemsList"
      :key="index"
      :class="[
        'mb-0',
        {
          'mx-4 mx-lg-8': extraHorizontalMargin,
          'mx-4 mb-5': !textItems,
          'mb-3 mb-lg-6 mx-4': textItems,
        },
      ]"
    >
      <client-only v-if="item.iconName === 'curacao'">
        <a
          v-if="item.iconName === 'curacao'"
          :href="getHref(item.route as string)"
          :target="getTargetAttrValue(item.route as string)"
          class="j-link"
        >
          <img
            src="https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900"
            alt="GCB"
            :width="String(item.iconWidth)"
            :height="String(item.iconHeight)"
            title="GCB Certificate of Operations"
            class="j-footer-items-list__link overflow-hidden"
          >
        </a>
      </client-only>
      <nuxt-link
        v-else-if="item.route"
        :to="getRouterPath(item.route)"
        :href="getHref(item.route)"
        :target="getTargetAttrValue(item.route)"
        :class="[
          'j-link',
          {
            'j-link--grayscale-hover-effect': isHoverEffect,
          },
        ]"
      >
        <v-img
          :lazy-src="useImgSrc(images[item.iconName])"
          :src="useImgSrc(images[item.iconName])"
          :width="getIconWidth(item.iconWidth)"
          :alt="`icon ${item.iconName}`"
        />
      </nuxt-link>
      <v-img
        v-else
        :lazy-src="useImgSrc(images[item.iconName])"
        :src="useImgSrc(images[item.iconName])"
        :width="getIconWidth(item.iconWidth)"
        :alt="`icon ${item.iconName}`"
      />
      <v-btn
        v-if="item.dictionaryKey"
        :to="getRouterPath(item.route)"
        :href="getHref(item.route)"
        variant="plain"
        class="px-0"
      >
        {{ $t(item.dictionaryKey) }}
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  IconWidth,
  InfoLogoItem,
} from '~/types/components/General/Footer';
import { TRUSTLOGO_VALIDATION_PLACEHOLDER } from '~/components/JFooter/constants';

const images = useAssetsImages();
const isMobile = inject<Ref<boolean>>('isMobile');
const nuxtApp = useNuxtApp();
const componentProps = defineProps({
  itemsList: {
    type: Array as PropType<InfoLogoItem[]>,
    default: () => [],
  },
  extraHorizontalMargin: {
    type: Boolean,
    default: () => false,
  },
  textItems: {
    type: Boolean,
    default: () => false,
  },
  isHoverEffect: {
    type: Boolean,
    default: () => false,
  },
});
const processedItemsList = computed(() => componentProps.itemsList.map(
    (item) => item.route === TRUSTLOGO_VALIDATION_PLACEHOLDER
      ? {
          ...item,
          route: getTrustLogoValidationUrl(),
        }
      : item,
));
/**
 * Generates a URL for TrustLogo validation with emulated click coordinates.
 * This function dynamically constructs a URL to query TrustLogo for SSL certificate
 * verification. It contains both server side and client side environments to compute
 * the origin dynamically which useful for handling different mirrors
 * The function simulates click coordinates on the icon to retrieve the necessary validation data.
 *
 * @returns {string} The constructed URL for TrustLogo validation with dynamic origin
 * and emulated click coordinates.
 */
const getTrustLogoValidationUrl = () => {
  const baseUrl = `https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=POSDV&v_search=${
    process.server
      ? `https://${nuxtApp?.ssrContext?.event.node.req.headers.host}`
      : window.location.origin
  }`;
  const x = Math.floor(Math.random() * 76) + 5;
  const y = Math.floor(Math.random() * 76) + 5;

  return `${baseUrl}&x=${x}&y=${y}`;
};
const getIconWidth = (iconWidth: IconWidth) => {
  if (iconWidth) {
    const displayModeName = isMobile?.value
      ? 'mobile'
      : 'desktop';

    return typeof iconWidth === 'number'
      ? iconWidth
      : iconWidth[displayModeName];
  }

  return 75;
};
const isExternalRoute = (route: string) => /^(http|https):\/\//.test(route);
const getRouterPath = (route: string) => isExternalRoute(route)
  ? undefined
  : useLocalePrefixForHref(route);
const getHref = (route: string) => isExternalRoute(route)
  ? route
  : undefined;
const getTargetAttrValue = (route: string) => isExternalRoute(route)
  ? '_blank'
  : '';
</script>
