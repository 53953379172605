<template>
  <v-app>
    <!--Header-->
    <component :is="Header as string" />

    <!--Content-->
    <v-main>
      <v-container class="j-container pa-lg-0">
        <slot />
      </v-container>
      <j-mobile-menu v-if="isMobile" />
    </v-main>

    <!--Footer-->
    <v-footer
      :color="scssVariables.jBgMain"
      class="ma-0 pa-0 justify-center"
    >
      <v-container class="j-container j-container--fw ma-0 pa-0">
        <j-footer />
      </v-container>
    </v-footer>
  </v-app>
</template>

<script lang="ts" setup>
const scssVariables = useScssVariables();
const isMobile = inject<Ref<boolean>>('isMobile');
const Header = computed(() => {
  return isMobile?.value
    ? resolveComponent('JHeaderMobile')
    : resolveComponent('JHeaderDesktop');
});
</script>

<style lang="scss">
.v-app {
  min-height: 100vh;
}

.v-main {
  background-color: $j-bg-main;
}
</style>
